import { cleanFormData, decrypt } from '@/utils'
import { CURRENT_BRANCH, AUTHENTICATED, LOGGED_IN, RECOVERY_PASSWORD, TOKEN } from '@/utils/constants'
import { sweetError, sweetSuccess } from '@/utils/sweetalert2'
import { saveAs } from 'file-saver'
import Cookies from 'js-cookie'
import axios from 'axios'

export const api = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

const type = (extension = 'xlsx') => {
  let type
  if (extension === 'docx') {
    type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  } else {
    type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
  return type
}

api.interceptors.request.use(function (config) {
  const token = decrypt(Cookies.get(TOKEN))
  const auth = [LOGGED_IN, RECOVERY_PASSWORD]
  config.baseURL = auth.includes(decrypt(Cookies.get(AUTHENTICATED))) ? process.env.NEXT_PUBLIC_API_AUTHENTICATED_URL : process.env.NEXT_PUBLIC_API_URL
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  if (Cookies.get(CURRENT_BRANCH)) {
    const currentBranch = JSON.parse(Cookies.get(CURRENT_BRANCH))
    if (currentBranch) {
      config.headers['X-Branch'] = currentBranch.id
    }
  }
  return config
})

export const post = async (url, data = {}, onLogOut) => {
  const auth = [LOGGED_IN, RECOVERY_PASSWORD]
  return new Promise((resolve, reject) => {
    api.post(url, cleanFormData(data))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        const errorMessage = (error?.response?.data?.error) ? error.response.data.error : error.message
        if (error.response.data.error === 'Usuario no autenticado.' && onLogOut) {
          onLogOut()
        }
        // console.log('casi entro', error.response.data.error, 'URL no encontrada.', onLogOut, auth.includes(decrypt(Cookies.get(AUTHENTICATED))))
        if (error.response.data.error === 'URL no encontrada.' && !auth.includes(decrypt(Cookies.get(AUTHENTICATED)))) {
          window.location.href = '/'
        }
      })
  })
}

export const post2 = async (url, data = {}, onLogOut) => {
  const auth = [LOGGED_IN, RECOVERY_PASSWORD]
  return new Promise((resolve, reject) => {
    api.post(url, cleanFormData(data))
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        const errorMessage = (error?.response?.data?.error) ? error.response.data.error : error.message
        const status = error.response.status
        const response = {
          status,
          error: errorMessage
        }
        if (error.response.data.error === 'Usuario no autenticado.' && onLogOut) {
          onLogOut()
        }
        if (error.response.data.error === 'URL no encontrada.' && !auth.includes(decrypt(Cookies.get(AUTHENTICATED)))) {
          window.location.href = '/'
        }
        reject(response)
      })
  })
}

export const get = async (url, data = {}, onLogOut) => {
  const auth = [LOGGED_IN, RECOVERY_PASSWORD]
  return new Promise((resolve, reject) => {
    api.get(url, { params: cleanFormData(data) }) // https://axios-http.com/docs/example
      .then(response => resolve(response.data))
      .catch(error => {
        if (error.response.data.error === 'Usuario no autenticado.' && onLogOut) {
          onLogOut()
        }
        if (error.response.data.error === 'URL no encontrada.' && !auth.includes(decrypt(Cookies.get(AUTHENTICATED)))) {
          window.location.href = '/'
        }
        const errorMessage = (error?.response?.data?.error) ? error.response.data.error : error.message
        reject(errorMessage)
      })
  })
}

export const getDocument = async (url, fileName, extension) => {
  try {
    const response = await api.get(url, true, {
      responseType: 'blob'
    }, true)
    const blob = new Blob([response], { type: "application/pdf" })
    saveAs(blob, `${fileName}.pdf`)
    sweetSuccess(`${fileName} generado`, 'El elemento se ha generado correctamente', 1500, () => {
    })
  } catch (error) {
    sweetError('', error)
  }
}

// export const postDocument = async (api, data, fileName, extension) => {
//   try {
//     const response = await api.post(api, cleanFormData(data), {
//       responseType: 'blob'
//     }, true)
//     const blob = new Blob([response], { type: type(extension) })
//     sweetSuccess(`${fileName} generado`, 'El elemento se ha generado correctamente', 1500, () => {
//       saveAs(blob, `${fileName}.${extension}`)
//     })
//   } catch (error) {
//     sweetError('', error)
//   }
// }

export const postDocument = async (url, data = {}, fileName = 'archivo', extension = 'pdf') => {
  return new Promise((resolve, reject) => {
    api.post(url, cleanFormData(data), {
      responseType: 'blob'
    }, true).then(response => {
      const blob = new Blob([response], { type: "application/pdf" })
      saveAs(blob, `${fileName}.${extension}`)
      // console.log('descarga')
      sweetSuccess(`${fileName} generado`, 'El elemento se ha generado correctamente', 1500, () => {
      })
      resolve()
    })
      .catch(error => {
        const errorMessage = (error?.response?.data?.error) ? error.response.data.error : error.message
        reject(errorMessage)
      })
  })
}
